.dropzone {
  padding: 30px;
  height: 20vh;
  border: 5px dashed #428bca;
  outline: none;
  cursor: pointer;
}

.dropzone-active {
  background-color: #f1e2ff;
  border: 5px solid #428bca;
}

.dropzone-content {
  align-self: center;
  font-size: 24px;
}
