
.text-center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
}

/* Dropzone */
.dropzone {
  padding: 30px;
  height: 30vh;
  border: 5px dashed #7649bc;
  outline: none;
  cursor: pointer;
}

.dropzone-active {
  background-color: #f1e2ff;
  border: 5px solid #2fd7b5;
}

.dropzone-content {
  align-self: center;
  font-size: 24px;
}

/* Images */
.file-list {
  margin: 20px 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.file-item {
  width: calc(25% - 20px);
  height: 150px;
  /*height: 10%;*/
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /*margin: 10px;*/
  cursor: move;
  transition: all 0.2s linear;
}

.file-img {
  width: 100%;
  height: 100%;
}

.file-img-modal {
  width: 30%;
  height: 30%;
}

/* Tablets */
@media (max-width: 1000px) {
  .App {
    max-width: 600px;
  }

  .file-item {
    width: calc(50% - 22px);
    height: 200px;
  }
}

/* Mobiles */
@media (max-width: 640px) {
  .App {
    max-width: 100%;
    padding: 0 15px;
  }

  .file-item {
    width: calc(100% - 22px);
    height: 200px;
  }
}
